import React from 'react';
import { Mail, Phone } from '../../components-lib/Icons';
import styles from './index.module.scss';


const GDPR = () => (
  <section className={styles.wrapper}>
    <section className={styles.content}>
      <h2 className={styles.title}>
        PODMIENKY OCHRANY OSOBNÝCH ÚDAJOV V RÁMCI PROJEKTU<br />
        „PRÁVNE SLUŽBY AKO PREVENCIA A ELIMINÁCIA DISKRIMINÁCIE“
      </h2>
      <p>
        <i>
        podľa článkov 13 a 14  nariadenia Európskeho parlamentu a Rady 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej len <b>„GDPR“</b>)
        </i>
      </p>
      <p>
      Dovoľujeme si Vás stručne informovať o spracovaní Vašich osobných údajov.
      </p>
      <ol className={styles.mainList}>
        <li>
          <h4>KTO JE PREVÁDZKOVATEĽOM VAŠICH OSOBNÝCH ÚDAJOV?</h4>
          <p>
          Prevádzkovateľom Vašich osobných údajov je:
          </p>
          <p>
            <b>Advokátska kancelária SEMANČÍN & PARTNERS s. r. o.</b>, sídlo Vajnorská 100/A, Bratislava 831 04, IČO 50 567 942, zapísaná v obchodnom registri Okresného súdu Bratislava I, Oddiel: Sro, vložka číslo: 114801/B (ďalej len <b>„Prevádzkovateľ“</b>). 
          </p>
          <div className={styles.contactsGroup}>
            <h4 className={styles.alignCenter}>Kontakt:</h4>
            <div className={styles.contacts}>
              <span className={styles.contactsItem}>
                <Mail color="black" /> <a href="mailto:info@diskriminacia-semancin.sk">info@diskriminacia-semancin.sk</a>
              </span>
              <span className={styles.contactsItem}>
                <Phone color="black" /> <a href="tel:421-2-32-609-451">+421 2 32 609 451</a>
              </span>
            </div>
          </div>
        </li>
        <li>
          <h4>NA KOHO SA V PRÍPADE DOTAZOV MÔŽEM OBRÁTIŤ?</h4>
          <p>
          V prípade dotazov alebo otázok k ochrane Vašich osobných údajov nás môžete kedykoľvek kontaktovať prostredníctvom uvedených kontaktných údajov alebo poštou na adresu sídla Prevádzkovateľa.
          </p>
        </li>
        <li>
          <h4>V AKÝCH SITUÁCIÁCH BUDE PREVÁDZKOVATEĽ VAŠE ÚDAJE SPRACÚVAŤ?</h4>
          <p>
          Vaše osobné údaje spracúvame bez Vášho súhlasu len v prípadoch, kedy nám platné právne predpisy takéto spracúvanie povoľujú. Osobné údaje, ktoré nám poskytujete na základe zmluvy, osobitného predpisu alebo na základe oprávneného záujmu, nám musíte poskytnúť, v opačnom prípade nebudeme môcť splniť Vaše ani naše povinnosti určené pre daný účel. Na to, aby sme Vám mohli poskytnúť právne poradenstvo, nám Vaše osobné údaje musíte poskytnúť, v opačnom prípade Vám naše právne služby nemôžme poskytnúť v rámci projektu realizovaného z fondov EÚ. 
          </p>
          <p>
          Bez Vášho súhlasu spracúvame Vaše osobné údaje len v týchto prípadoch a na základe týchto právnych základov:
          </p>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV</th>
                <th>PRÁVNY ZÁKLAD</th>
                <th>DOBA UCHOVÁVANIA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3}>
                Ak máte záujem a požiadate nás o poskytovanie právnych služieb v rámci projektu financovaného zo zdrojov EÚ s názvom „Právne služby ako prevencia a eliminácia diskriminácie“, v zmysle GDPR sa stanete naším potenciálnym klientom, klientom,  teda dotknutou osobou, ktorej osobné údaje spracúvame v rozsahu nevyhnutných bežných údajov a osobitnej kategórie osobných údajov (napr. etnický pôvod) na:
                </td>
              </tr>
              <tr>
                <th>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV</th>
                <td>
                Výkon advokácie v zmysle § 1 ods. 2 zákona č. 586/2003 Z. z. o advokácii v platnom znení.	
                </td>
                <th>PRÁVNY ZÁKLAD</th>
                <td>
                  <p>
                    <b>Čl. 6 ods. 1 písm. b) GDPR</b> - plnenie zmluvy s potenciálnym klientom, klientom, vrátane plnenia predzmluvných vzťahov.
                  </p>
                  <p>
                    <b>Čl. 6 ods. 1 písm. f) GDPR</b> - oprávnený záujem Prevádzkovateľa na spracúvaní osobných údajov fyzických osôb, ktoré zastupujú potenciálnych klientov, klientov – <u>oprávnených zástupcov, kontaktných osôb.</u>
                  </p>

                  <p><i>Oprávnený záujem advokátskej kancelárie na výkone predmetu činnosti.</i></p>

                  <p><b><i>... v spojení s výnimkou zo zákazu spracúvania osobitnej kategórie osobných údajov:</i></b></p>

                  <p>
                    <b>Čl. 9 ods. 1 písm. f) GDPR – </b>nakoľko spracúvanie takýchto údajov je alebo môže byť nevyhnutné na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov (napr. klienta).   
                  </p>
                </td>
                <th>DOBA UCHOVÁVANIA</th>
                <td>
                Do ukončenia plnenia práv a povinností vyplývajúcich zo zmluvného, predzmluvného vzťahu a do uplynutia aplikovateľných premlčacích, prekluzívnych, reklamačných, uschovacích a iných obdobných lehôt, a to do uplynutia poslednej z týchto lehôt.
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <i>S tým môže byť spojené aj spracúvanie údajov <b><u>klientov</u> v rozsahu nevyhnutných bežných údajov a osobitnej kategórie osobných údajov</b> na <b>výkon našej agendy v oblasti ochrany pred legalizáciou príjmov z trestnej činnosti a terorizmom na:</b></i>
                </td>
              </tr>
              <tr>
                <th>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV</th>
                <td>
                Vlastný program činnosti v zmysle § 20 ods. 1 zákona č. 297/2008 Z. z. o ochrane pred legalizáciou  príjmov z trestnej činnosti a ochrane pred financovaním terorizmu a o zmene a doplnení niektorých zákonov.
                </td>
                <th>PRÁVNY ZÁKLAD</th>
                <td>
                  <p>
                    <b>Čl. 6 ods. 1 písm. c) GDPR – </b>spracúvanie je nevyhnutné na plnenie zákonných povinností Prevádzkovateľa v zmysle osobitného predpisu.
                  </p>
                  <p><b><i>... v spojení s výnimkou zo zákazu spracúvania osobitnej kategórie osobných údajov:</i></b></p>
                  <p>
                    <b>Čl. 9 ods. 1 písm. g) GDPR - </b>spracúvanie je nevyhnutné z dôvodov významného verejného záujmu na základe práva Únie.
                  </p>
                </td>
                <th>DOBA UCHOVÁVANIA</th>
                <td>Zákonné lehoty uchovávania.</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <i>Pri poskytovaní právnych služieb nám môžete oznamovať aj osobné údaje <b><i><u>protistrán</u></i></b>, voči ktorým budeme hájiť Vaše záujmy v rozsahu <b>nevyhnutných bežných údajov a osobitnej kategórie osobných údajov na:</b></i>
                </td>
              </tr>
              <tr>
                <th>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV</th>
                <td>
                Výkon advokácie v zmysle § 1 ods. (2) Zákona č. 586/2003 Z. z. o advokácii v platnom znení.
                </td>
                <th>PRÁVNY ZÁKLAD</th>
                <td>
                  <p>
                    <b>Čl. 6 ods. 1 písm. f) GDPR – </b>oprávnený záujem Prevádzkovateľa na spracúvaní osobných údajov dotknutých osôb – protistrany a jej oprávnených zástupcov a kontaktných osôb.
                  </p>
                  <p>
                    <i>Oprávnený záujem advokátskej kancelárie na výkone predmetu činnosti.</i>
                  </p>

                  <p><b><i>... v spojení s výnimkou zo zákazu spracúvania osobitnej kategórie osobných údajov:</i></b></p>

                  <p>
                    <b>Čl. 9 ods. 1 písm. f) GDPR – </b>nakoľko spracúvanie takýchto údajov je alebo môže byť nevyhnutné na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov.
                  </p>
                </td>
                <th>DOBA UCHOVÁVANIA</th>
                <td>
                Do ukončenia plnenia práv a povinností vyplývajúcich zo zmluvného, predzmluvného vzťahu a do uplynutia aplikovateľných premlčacích, prekluzívnych, reklamačných, uschovacích a iných obdobných lehôt, a to do uplynutia poslednej z týchto lehôt.
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <i>
                  Napokon, osobné údaje, ktoré nám poskytnete, <b>aby sme Vám mohli poskytnúť naše právne služby</b> v rámci projektu financovaného zo zdrojov EÚ s názvom „Právne služby ako prevencia a eliminácia diskriminácie“ a osobné údaje osôb zúčastnených na projekte definovaných v zákone č. 292/2014 Z. z. o príspevku poskytovanom z európskych štrukturálnych a investičných fondov a o zmene a doplnení niektorých zákonov (ďalej len <b>„Zákon o eurofondoch“</b>) v rozsahu <b>bežných a osobitnej kategórie osobných údajov v zmysle § 47 ods. 2 Zákona o eurofondoch</b> budeme spracúvať na: 
                  </i>
                </td>
              </tr>
              <tr>
                <th>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV</th>
                <td>
                Vytvorenie komunikačného kanála pre možné dotazy a komunikáciu s dotknutými osobami (webový formulár).
                </td>
                <th>PRÁVNY ZÁKLAD</th>
                <td>
                  <p>V závislosti od obsahu dopytu:</p>
                  <p>
                    <b>Čl. 6 ods. 1 písm. b) – </b>spracúvanie je nevyhnutné na plnenie predzmluvných vzťahov (ak potenciálny klient je fyzickou osobou).
                  </p>
                  <p>
                    <b>Čl. 6 ods. 1 písm. f) GDPR – </b>oprávnený záujem Prevádzkovateľa na spracúvaní osobných údajov dotknutých osôb, ktoré potencionálnych klientoch zastupujú.
                  </p>
                  <p>
                    <i>Oprávnený záujem Prevádzkovateľa na komunikácii, jeho získanie a starostlivosť o potenciálneho  klienta, ktorý  je právnickou osobou prostredníctvom osoby, ktorá právnickú osobu zastupuje, alebo ktorý je fyzickou osobou zastúpenou inou oprávnenou fyzickou osobou a starostlivosť o neho, a v prípade ak sa nejedná o predzmluvný vzťah, tak Prevádzkovateľ má oprávnený záujem na vyriešenie iného dopytu dotknutej osoby.</i>
                  </p>
                </td>
                <th>DOBA UCHOVÁVANIA</th>
                <td>
                Do ukončenia plnenia predzmluvného vzťahu, prípadne riešenia iného dopytu.
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <i>
                  Na poskytovanie našich služieb pre Vás ako <b>potencionálnych klientov alebo len záujemcov o naše služby</b> vytvorili aj online chat ako ďalší komunikačný kanál na <a href="/">www.diskriminacia-semancin.sk</a>, aby sme Vám zjednodušili komunikáciu s nami a boli Vám k dispozícii, kde spracúvame o Vás <b>bežné osobné údaje, najmä IP adresa,</b> nevyhnutné na umožnenie online komunikácie s Vami na:
                  </i>
                </td>
              </tr>
              <tr>
                <th>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV</th>
                <td>
                Vytvorenie komunikačného kanála pre možné dotazy a komunikáciu s dotknutými osobami (online chat).
                </td>
                <th>PRÁVNY ZÁKLAD</th>
                <td>
                  <p>V závislosti od obsahu dopytu:</p> 
                  <p><b>Čl. 6 ods. 1 písm. b) – </b>spracúvanie je nevyhnutné na plnenie predzmluvných vzťahov ( ak potenciálny klient je fyzickou osobou).</p>
                  <p><b>Čl. 6 ods. 1 písm. f) GDPR – </b>oprávnený záujem Prevádzkovateľa na spracúvaní osobných údajov dotknutých osôb, ktoré potencionálnych klientoch zastupujú.</p>
                  <p>
                    <i>Oprávnený záujem Prevádzkovateľa na komunikácii, jeho získanie a starostlivosť o potenciálneho  klienta, ktorý  je právnickou osobou prostredníctvom osoby, ktorá právnickú osobu zastupuje, alebo ktorý je fyzickou osobou zastúpenou inou oprávnenou fyzickou osobou a starostlivosť o neho, a v prípade ak sa nejedná o predzmluvný vzťah, tak Prevádzkovateľ má oprávnený záujem na vyriešenie iného dopytu dotknutej osoby.</i>
                  </p>
                </td>
                <th>DOBA UCHOVÁVANIA</th>
                <td>
                Do ukončenia plnenia predzmluvného vzťahu, prípadne riešenia iného dopytu.
                </td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          <h4>PO AKÚ DOBU BUDEME SPRACÚVAŤ VAŠE OSOBNÉ ÚDAJE?</h4>
          <p>
          Vaše osobné údaje uchovávame najviac dovtedy, kým je to potrebné na naplnenie uvedených účelov. Pri uchovávaní osobných údajov sa riadime osobitnými predpismi, ktoré nám stanovujú lehoty uloženia a/alebo základnými zásadami GDPR ohľadom uchovávania a likvidácie osobných údajov. 
          </p>
        </li>
        <li>
          <h4>Z AKÝCH ZDROJOV TIETO INFORMÁCIE POCHÁDZAJÚ?</h4>
          <p>
          Spracúvame najmä údaje, ktoré ste nám poskytli v súvislosti s uzatvorením zmluvy alebo ste nám ich oznámili v priebehu našej spolupráce, alebo nám ich poskytla iná fyzická osoba, ktorá Vás zastupuje alebo sme sa o nich dozvedeli z verejných registrov.
          </p>
        </li>
        <li>
          <h4>KTO JE PRÍJEMCOM OSOBNÝCH ÚDAJOV</h4>
          <p>
          Vaše osobné údaje poskytujeme iba v odôvodnených prípadoch a iba v nevyhnutnom rozsahu týmto kategóriám príjemcov:
          </p>
          <ul className={styles.innerList}>
            <li>
            v nevyhnutnom rozsahu našim zmluvným partnerom, ktorých potrebujeme na realizáciu spracúvania osobných údajov v rámci projektu „Právne služby ako prevencia a eliminácia diskriminácie“ (Detect, Praetor, Octigon, a.s., poskytovateľ produktov a služieb pod značkou Livechatoo.com a pod.);
            </li>
            <li>
            v nevyhnutnom rozsahu oprávneným orgánom v zmysle Zákona o eurofondoch;
            </li>
            <li>
            v nevyhnutnom rozsahu iným subjektom v prípadoch, kedy nám právo alebo povinnosť poskytnutia Vašich osobných údajov ukladajú právne predpisy, alebo ak je to nutné na ochranu našich oprávnených záujmov (napr. súdom, Polícií a pod.).
            </li>
          </ul>
        </li>
        <li>
          <h4>PRENOS OSOBNÝCH ÚDAJOV DO TRETÍCH KRAJÍN</h4>
          <p>Vaše osobné údaje neposkytujeme do tretích krajín mimo EÚ.</p>
        </li>
        <li>
          <h4>AUTOMATIZOVANÉ ROZHODOVANIE A PROFILOVANIE</h4>
          <p>Vaše osobné údaje nie sú a nebudú použité v rámci automatizovaného individuálneho rozhodovania a ani na profilovanie.</p>
        </li>
        <li>
          <h4>AKÉ MÁTE PRÁVA PRI SPRACOVANÍ VAŠICH OSOBNÝCH ÚDAJOV?</h4>
          <ul className={styles.innerList}>
            <li>
              <b>právo na prístup</b> – môžete nás požiadať o prístup k osobným údajom, ktoré o Vás spracúvame. Prevádzkovateľ poskytne aj kópiu spracúvaných osobných údajov. 
            </li>
            <li>
              <b>právo na opravu</b> – môžete nás požiadať o opravu nepresných alebo nekompletných osobných údajov, ktoré o Vás spracúvame.
            </li>
            <li>
              <b>právo na výmaz</b> - môžete nás požiadať, aby sme vymazali Vaše osobné údaje, ak dôjde k niektorej z nasledujúcich situácií:
              <ol className={styles.innerListRoman}>
                <li>osobné údaje už nie sú potrebné na účely, na ktoré sa získavali alebo inak spracúvali;</li>
                <li>
                v minulosti ste nám na spracúvanie poskytli súhlas, ktorý odvoláte a my zároveň nie sme oprávnený spracúvať predmetné osobné údaje bez Vášho súhlasu; 
                </li>
                <li>
                namietate voči spracúvaniu vykonávanom v špecifických situáciách podľa GDPR (úloha realizovaná vo verejnom záujme, oprávnený záujem Prevádzkovateľa alebo profilovanie) a nad vašimi záujmami, právami a slobodami neprevažujú žiadne oprávnené dôvody na spracúvanie alebo
                </li>
                <li>
                namietate voči spracúvaniu na účely priameho marketingu;
                </li>
                <li>
                osobné údaje sa spracúvali nezákonne;
                </li>
                <li>
                osobné údaje musia byť vymazané, aby sa splnila zákonná povinnosť podľa práva EÚ alebo práva Členského Štátu, ktorému Prevádzkovateľ podlieha; 
                </li>
                <li>
                osobné údaje sa získavali v súvislosti s ponukou služieb informačnej spoločnosti podľa GDPR.
                </li>
              </ol>
            </li>
            <li>
              <b>právo na obmedzenie spracúvania</b> - môžete Prevádzkovateľa požiadať, aby obmedzil spracúvanie Vašich osobných údajov, ak dôjde k niektorej z nasledujúcich situácií:
              <ol className={styles.innerListRoman}>
                <li>
                popreli ste presnosť osobných údajov, a to na dobu potrebnú k tomu, aby Prevádzkovateľ mohol presnosť osobných údajov overiť;
                </li>
                <li>
                spracovanie Vašich osobných údajov je protiprávne, ale odmietate výmaz týchto údajov a namiesto toho žiadate o obmedzenie ich použitia;
                </li>
                <li>
                prevádzkovateľ už osobné údaje nepotrebuje na účely spracovania, ale Vy ich požadujete na určenie, výkon alebo obhajobu právnych nárokov;
                </li>
                <li>
                vzniesli ste námietku proti spracovaniu Vašich osobných údajov v špecifických situáciách podľa GDPR (úloha realizovaná vo verejnom záujme, oprávnený záujem Prevádzkovateľa alebo profilovanie), a to až kým nebude overené, že oprávnené dôvody Prevádzkovateľa prevažujú nad Vašimi oprávnenými dôvodmi. 
                </li>
              </ol>
            </li>
            <li>
              <b>právo na prenosnosť údajov</b> – ak spracúvame Vaše osobné údaje na základe Vášho súhlasu alebo preto, že je to nevyhnutné na plnenie zmluvy, ktorej ste zmluvnou stranou,  a zároveň ide o spracúvanie automatizovanými prostriedkami spracúvania, máte právo získať osobné údaje, ktoré sa Vás týkajú, a ktoré ste poskytli Prevádzkovateľovi, v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte s tým, že týmto právom nesmú byť nepriaznivo dotknuté práva a slobody iných osôb.
            </li>
            <li>
              <b>právo vzniesť námietku</b> – môžete kedykoľvek vzniesť námietku proti spracovaniu Vašich osobných údajov u Prevádzkovateľa na účely priameho marketingu vykonávaného na základe oprávneného záujmu Prevádzkovateľa a vždy vtedy, keď Vaše osobné údaje spracúvame na základe oprávneného záujmu alebo verejného záujmu, vrátane profilovania.
            </li>
            <li>
              <b>právo podať sťažnosť</b> – máte právo podať sťažnosť na dozornom orgáne, ktorým je Úrad na ochranu osobných údajov Slovenskej republiky, Hraničná 12, 820 07 Bratislava 27, <a href="https://dataprotection.gov.sk/uoou/" target="blank">www.uoou.sk</a>.
            </li>
          </ul>
          <p>
            <b>Vaše práva môžu byť obmedzené relevantným právom EÚ alebo Členského Štátu.</b>
          </p>
          <p>
          Ako dotknutá osoba si môže uplatniť svoje práva ústne, písomne alebo elektronicky, cez vyššie uvedené kontaktné údaje. Bez zbytočného odkladu Vám poskytneme informácie o opatreniach, ktoré sa prijali na základe Vašej žiadosti, najneskôr do jedného mesiaca od doručenia Vašej žiadosti. Prevádzkovateľ môže požiadať o poskytnutie dodatočných informácií potrebných na potvrdenie totožnosti dotknutej osoby, ak má oprávnené pochybnosti o totožnosti fyzickej osoby, ktorá si uplatnila svoje práva prostredníctvom žiadosti o výkon práva dotknutej osoby. Je povinnosťou Prevádzkovateľa zabrániť poskytnutiu osobných údajov neoprávnenej osobe. V prípade, že túto lehotu budeme v odôvodnených prípadoch potrebovať predĺžiť, budeme Vás o tom včas informovať. Ak by bola žiadosť o výkon práv dotknutej osoby zjavne neopodstatnená alebo neprimeraná, najmä v dôsledku jej opakujúcej sa povahy, môžeme odmietnuť vybaviť Vašu žiadosť alebo môžeme od Vás požadovať primeraný poplatok zohľadňujúci naše administratívne náklady na jej vybavenie. 
          </p>
        </li>
      </ol>
      <a
        className={styles.personalDataUsageLink}
        href="https://www.semancin.sk/ochrana-osobnych-udajov/"
        target="blank"
      >
      Viac informácia o podmienkach ochrany osobných údajov Prevádzkovateľom nájdete na www.semancin.sk.
      </a>
    </section>
  </section>
);

export default GDPR;
